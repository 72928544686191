<template>
  <div class="entrustChildList">
    <div class="banner"><img src="./img/banner.png" alt="" /></div>
    <div class="body">
      <div class="box">
        <div class="item">
          <div class="item-l">家长姓名</div>
          <div class="item-r">
            <v-input
              v-model="form.parentsName"
              :maxlength="10"
              placeholder="请填写姓名"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l">家长电话</div>
          <div class="item-r">
            <v-input
              type="number"
              v-model="form.parentsPhone"
              :maxlength="11"
              placeholder="请填写电话"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l">孩子姓名</div>
          <div class="item-r">
            <v-input
              v-model="form.childName"
              :maxlength="20"
              placeholder="请填写姓名"
            ></v-input>
          </div>
        </div>
      </div>
      <div class="timeAppoint" v-if="submitParams.type == 2">
        <div class="oList" v-for="(item, index) in appointData" :key="index">
          <div class="oList-t">{{ item.dayName }}</div>
          <div
            class="oList-b"
            v-for="(oItem, oIndex) in item.detailId"
            :key="oIndex"
          >
            <div class="oList-bl">{{ weekArrMap[oItem.weekName] }}</div>
            <div class="oList-br">
              {{ oItem.beginTime + "~" + oItem.endTime }}
            </div>
          </div>
        </div>
      </div>
      <div class="toinfo">
        <div class="img" @click="toSelect">
          <img v-if="isselect" src="./img/appoint.png" alt="" /><img
            src="./img/appointed.png"
            v-else
            alt=""
          />
        </div>
        <div class="txt">
          我已阅读并同意<span @click="toinfo">《托管说明＆入托须知》</span>
        </div>
      </div>
      <div class="surebtn" @click="submit">
        <div
          :class="[
            'btn',
            {
              light:
                form.parentsName &&
                form.parentsPhone &&
                form.childName &&
                !isselect,
            },
          ]"
        >
          提交
        </div>
      </div>
    </div>
    <div class="dialog-info" v-if="isInfo">
      <div class="box">
        <div class="entrustChildInfo">
          <h3>春城社区婴幼儿临时托管说明</h3>
          <p>
            为更好的服务社区婴幼儿家庭，解决婴幼儿临时无人照顾难题，春城社区推出临时性托管服务，具体事宜如下：
          </p>
          <p>一、托管对象：春城社区管辖范围内6个月-3岁婴幼儿。（家长不陪同）</p>
          <p>
            二、托管时间：一般为临时性托管，一次托管1-3小时，一般不超过3小时。每位婴幼儿每月累计托管不超过24小时。
          </p>
          <p>
            三、托管地点：木木橙港隆园（嘉里玫瑰苑沿街）或春城社区党群服务中心
          </p>
          <p>四、托管须知：</p>
          <p>
            1、临时托半日托入园需持健康体检保健册，疫苗接种复印件和监护人身份证明（户口页复印件）。
          </p>
          <p>
            2、携带幼儿临时所需生活用品（奶粉，尿不湿，湿巾纸巾，水杯，依恋物，不允许携带其他类型食品药品）。
          </p>
          <p>3、离园前检查好个人所有物品。</p>
          <p>
            4、社区内家庭预约临时托，需在社区“春城助手”小程序“社区托幼”模块内预约为有效。
          </p>
          <p>咨询电话：15336618108（周老师）</p>
        </div>
        <div class="entrustChildInfo">
          <h3>春城社区临时托入托须知</h3>
          <p>一、注意事项</p>
          <p>
            为明确合同当事人的权利和义务，根据《中华人民共和国合同法》、《中华人民共和国民办教育促进法》及其他相关法律、法规的规定，经过协商一致，达成如下合同条款：
          </p>
          <p>
            1、为了孩子安全，请家长不要让孩子佩带各种发卡、身上不宜佩带各种饰物、携带贵重物品、危险品进入本园区。进入本园区前请家长检查幼儿口袋，如发现有危险物品(如小刀、药片、铁钉、弹珠、小颗粒物、碎玻璃、各种发卡等物品)应立即取出，并对幼儿进行教育。
          </p>
          <p>
            2、接送孩子时请遵守作息时间,8:30-9:00为入园时间，请不要让孩子无故缺席、迟到，孩子因为各种原因不能到园，请事先向老师做好请假工作。
          </p>
          <p>
            3、如孩子来园前发现身体不适，(如在家中已经发热、呕吐、绞痛，服过药，以及经医院治疗须服用药)均应在入园时与本园区值班教师详细介绍情况，说明幼儿的病情，服药情况和服药要求。幼儿如有药品带入园区中，家长必须与园区值班老师亲自交待服用方式并作好带药登记并签字。
          </p>
          <p>
            4、幼儿发烧37度以上、缝针、骨折未愈、哮喘发作期，均不能入园。凡患有传染病的幼儿，一律按市防疫站儿保所规定进行隔离，期满后由医院证明方可入园，如隐瞒病史，所造成的任何后果，都由乙方自行责任自负。
          </p>
          <p>
            5、幼儿在园期间发生磕碰小伤、肿痛、蚊虫叮咬等情况，托育园可提供简单的辅助治疗。如需要医院治疗，由家长带往医院治疗。如需留园区观察，期间家长应主动打电话与本园区取得联系，及时了解孩子的身体状况。意外造成所产生的费用，应由本园区与家长协商后共同承担。
          </p>
          <p>
            6、入托前需提前联系园区并提供幼儿信息和家长信息，确保购买幼儿保险成功之后方可入托。
          </p>
          <p>
            7、如果家长发现幼儿在本园区生活和学习期间出现任何伤害情况，须带走幼儿之前当面检查并指出存在的任何问题，否则托育园不予受理。
          </p>
        </div>
      </div>
      <div class="close" @click="closeInfo">
        <img src="@/assets/img/close.png" alt="" />
      </div>
    </div>
    <!-- <v-dateTimePicker
      :value="form.startTime"
      :valueShow.sync="actiStartShow"
      :isAuto="true"
      type="time"
      :title="'选择时间'"
      :max-hour="form.endTime ? form.endTime.split(':')[0] : '23'"
      @confirmPicker="confirmActiStart"
    ></v-dateTimePicker>
    <v-dateTimePicker
      :value="form.endTime"
      :isAuto="true"
      type="time"
      :title="'选择时间'"
      :valueShow.sync="actiEndShow"
      @confirmPicker="confirmActiEnd"
      :min-hour="form.startTime ? form.startTime.split(':')[0] : '00'"
    ></v-dateTimePicker> -->
  </div>
</template>

<script>
import { nurseryApplyUrl, getLastOrderUrl } from "./api.js";
const moment = require("moment");
import { weekArrMap } from "./map";
export default {
  name: "entrustChildList",
  data() {
    return {
      weekArrMap,
      isInfo: false,
      isselect: true,
      scheduleId: "",
      // actiStartShow: false,
      // actiEndShow: false,
      form: {
        childName: "",
        parentsName: "",
        parentsPhone: "",
        // endTime: "",
        // startTime: "",
        // initDate: "00:00",
      },
      appointData: [],
      submitParams: {
        scheduleId: "",
        detailId: "",
        type: "",
        dayName: "",
      },
    };
  },
  // filters: {
  //   dateFormat: function (value) {
  //     if (value) {
  //       return moment(value).format("HH:mm");
  //     }
  //     return moment(new Date()).format("HH:mm");
  //   },
  // },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  async created() {
    const { scheduleId, detailId, type, dayName } = this.$route.query;
    const params = {
      scheduleId,
      detailId,
      type,
      dayName,
    };
    if (localStorage.getItem("appointData")) {
      this.appointData = JSON.parse(localStorage.getItem("appointData"));
    }
    this.submitParams = params;
    await this.getLastOrder();
  },
  methods: {
    // toStart() {
    //   this.actiStartShow = true;
    // },
    // toEnd() {
    //   this.actiEndShow = true;
    // },
    // confirmActiStart(value) {
    //   console.log(value);
    //   this.form.startTime = value;
    // },
    // confirmActiEnd(value) {
    //   this.form.endTime = value;
    // },
    // 获取上一次预约信息
    async getLastOrder() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(getLastOrderUrl, { params });
      if (res.code === 200) {
        const keys = ["parentsName", "parentsPhone"];
        keys.forEach((key) => {
          this.form[key] = res.data[key];
        });
      }
    },
    toSelect() {
      this.isselect = !this.isselect;
    },
    closeInfo() {
      this.isInfo = false;
    },
    toinfo() {
      this.isInfo = true;
    },
    async submit() {
      if (
        !(
          this.form.parentsName &&
          this.form.parentsPhone &&
          this.form.childName &&
          !this.isselect
        )
      ) {
        return;
      }
      // let startTime =
      //   moment(new Date()).format("YYYY-MM-DD") +
      //   " " +
      //   this.form.startTime +
      //   ":00";
      // let endTime =
      //   moment(new Date()).format("YYYY-MM-DD") +
      //   " " +
      //   this.form.endTime +
      //   ":00";
      let params = {};
      if (this.submitParams.type == 1) {
        params = {
          parentsName: this.form.parentsName,
          parentsPhone: this.form.parentsPhone,
          childName: this.form.childName,
          userId: this.userId,
          ...this.submitParams,
        };
      } else {
        let oData = {
          scheduleId: "",
          dayName: "",
          detailId: "",
        };
        let scheduleIdList = [];
        let dayNameList = [];
        let detailIdList = [];
        this.appointData.forEach((ele) => {
          scheduleIdList.push(ele.scheduleId);
          dayNameList.push(ele.dayName);
          let data = [];
          ele.detailId.forEach((item) => {
            data.push(item.detailId);
          });
          ele.detailIdData = data;
          ele.detailIdTxt = ele.detailIdData.join(",");
          detailIdList.push(ele.detailIdTxt);
        });
        oData.scheduleId = scheduleIdList.join(";");
        oData.dayName = dayNameList.join(";");
        oData.detailId = detailIdList.join(";");
        params = {
          parentsName: this.form.parentsName,
          parentsPhone: this.form.parentsPhone,
          childName: this.form.childName,
          userId: this.userId,
          type: 2,
          ...oData,
        };
      }

      let res = await this.$axios.post(nurseryApplyUrl, params);
      if (res.code === 200) {
        this.$toast("已提交申请");
        localStorage.removeItem("appointData");
        this.$router.push({
          name: "entrustChild",
          query: {
            type: this.submitParams.type,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.entrustChildList {
  min-height: 100%;
  background: #fafafa;
  .banner {
    height: 318px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dialog-info {
    width: 90%;
    height: 70%;
    background: #fff;
    border-radius: 20px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 10%);
    .box {
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 30px;
    }
    .close {
      width: 60px;
      height: 60px;
      position: absolute;
      bottom: -90px;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 9999;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .entrustChildInfo {
      h3 {
        font-size: 36px;
        text-align: center;
        margin: 20px 0;
        font-weight: 700;
      }
      p {
        font-size: 30px;
        text-indent: 2em;
        margin: 20px 0;
      }
    }
  }
  .body {
    padding: 0 32px;
    .box {
      position: relative;
      margin-top: -40px;
      background: #fff;
      padding: 10px 14px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 46px;
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        padding: 32px 14px;
        &:not(:last-child) {
          border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        }
        .item-l {
          min-width: 100px;
        }
        .item-r {
          flex: 1;
        }
        /deep/ .van-cell {
          padding: 0;
          input {
            text-align: right;
          }
        }
      }
      .time {
        padding: 26px 14px 30px;
        .title {
          font-size: 28px;
          font-weight: 600;
          color: #000000;
          line-height: 46px;
        }
        .time-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 30px 0 0;
          .item-l {
            font-size: 32px;
            font-weight: 400;
            color: #333333;
            line-height: 44px;
          }
          .item-r {
            width: 124px;
            height: 62px;
            background: #efefef;
            border-radius: 20px;
            font-size: 32px;
            font-weight: 600;
            color: #000000;
            line-height: 62px;
            text-align: center;
          }
        }
      }
    }
    .toinfo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 60px auto 20px;
      .img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 30px;
      }
      span {
        color: #fe9514;
        margin-right: 10px;
      }
    }
    .timeAppoint {
      padding: 32px 28px;
      margin: 20px auto;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      font-size: 28px;
      color: #666;
      .oList-t {
        margin-bottom: 10px;
        font-weight: 700;
      }
      .oList-b {
        display: flex;
        align-items: center;
        margin: 10px 0;
        .oList-bl {
          margin-right: 10px;
          line-height: 1;
        }
      }
    }
    .surebtn {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        width: 550px;
        height: 66px;
        font-size: 28px;
        line-height: 66px;
        text-align: center;
        font-weight: bold;
        border-radius: 10px;
        background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
        color: #a7a7a7;
        &.light {
          color: #ffffff;
          background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
        }
      }
    }
  }
}
.entrustChildList {
}
</style>
